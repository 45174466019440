import Cookies from 'js-cookie';
import gql from 'graphql-tag';
import {ApolloClient} from "@apollo/client";
import {AUTH_STATE, authState, loggedIn} from "../graphql/type-policies/auth";

export interface User {
  readonly email: string
  readonly password?: string
}

export interface IAccount {
  readonly __typename: string
  readonly id: string
  readonly name: string
}

export enum AUTH_PROVIDERS {
  EMAIL = "eMail",
  OAUTH = "oAuth",
}

export interface authToken {
  readonly _provider: string
  readonly _profile: User
}

export const LOG_IN_USER = gql`
  mutation LogIn($email: String!, $password: String!) {
    AccountLogin(email: $email, password: $password) {
      jwt
    }
  }
`

export const REGISTER_USER = gql`
  mutation Register($email: String!, $password: String!, $name: String!) {
    AccountSignUp(email: $email, password: $password, name: $name) {
      id name email
    }
  }
`

export class AuthService {

  constructor (private client: ApolloClient<any>) {

  }

  public async logIn({_provider, _profile}: authToken): Promise<string> {
    authState(AUTH_STATE.WAITING)
    AuthService.clearAuthToken()
    console.log("Logging in!")

    if (_provider === AUTH_PROVIDERS.EMAIL) {
      const res = await this.client
        .mutate({
          mutation: LOG_IN_USER,
          variables: _profile
        });
      const {data: {AccountLogin: {jwt = ''}}} = res as any;

      if (!!jwt) {
        console.log("Setting logged in!")
        this.setLoggedIn()
        // this.client.writeQuery({
        //   query: IS_LOGGED_IN,
        //   data: {
        //     isLoggedIn: true
        //   }
        // })
        AuthService.setAuthToken(jwt);
        return jwt;
      } else {
        authState(AUTH_STATE.LOGGED_OUT)
        return "";
      }
    } else {
      authState(AUTH_STATE.LOGGED_OUT)
      return "";
    }
  }

  public setLoggedIn() {
    console.log("Setting logged in")
    loggedIn(true)
    authState(AUTH_STATE.LOGGED_IN)
  }

  public async logOut() {
    loggedIn(false)
    authState(AUTH_STATE.WAITING)
    AuthService.clearAuthToken()
    try {
      await this.client.resetStore()
    } catch (e) {
      console.log(e)
    }
    authState(AUTH_STATE.LOGGED_OUT)
  }

  public static clearAuthToken() {
    Cookies.remove("authToken")
  }

  public static getAuthToken(prefix: string = ""): string {
    const authToken = Cookies.get('authToken')
    try {
      if (!authToken) {
        return "";
      } else {
        return `${!!prefix? `${prefix} `:""}${authToken}`;
      }
    } catch (e) {
      console.log(e)
      return "";
    }
  }

  public static setAuthToken(token: string) {
    AuthService.clearAuthToken()
    Cookies.set("authToken", token, {secure: true, sameSite: "Lax"});
  }
}
