import React, {useState} from "react";
import {useHistory, useParams} from "react-router";
import {EventityItem} from "../../components/Eventity/EventityItem";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {READ_EVENTITY} from "../../graphql/queries/eventity";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import {GenerateFromJoi} from "../../components/Joi/GenerateFromJoi";
import {Button, Card, Empty, Space, Tabs} from "antd";
import {toTitleCase} from "../../service/string";
import {commandMutation, eventityItemQuery, filterMetaFields} from "../../graphql/queries/generic";
import {ContentHeader} from "../layouts/style";
import {Stream} from "../../components/Stream/Stream";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {PanelContainer} from "./Components/style";
import Panels from "./Components/Panels";
import {conformResponseToSchema} from "../../service/joi";

interface ViewEventityItem {
  eventityName: string
  itemId: string
  item: any
}

const {TabPane} = Tabs

export const ViewEventityItem: React.FC<ViewEventityItem> = ({...props}) => {
  const {push} = useHistory()

  const workspaceId = useReactiveVar(currentWorkspaceId)
  const organisationId = useReactiveVar(currentOrgID)

  const {eventityName, itemId} = useParams<{ eventityName: string, itemId: string }>()

  const [edit, setEdit] = useState(false)

  const [formData, setFormData] = useState<any>({})

  const {data: {GetWorkspaceEntity: eventity} = {GetWorkspaceEntity: {schema: {}}}} = useQuery(
    READ_EVENTITY,
    {variables: {name: eventityName, workspaceId}}
  ) as any;

  const fields = filterMetaFields(Object.keys(eventity.schema))

  const queryText = eventityItemQuery(eventityName, ["id", ...fields], true)

  const {
    data: {
      content: {
        [eventityName]: {
          node: itemNode
        }
      }
    } = {content: {[eventityName]: {node: {history: []}}}},
    refetch,
    loading
  } = useQuery(
    queryText,
    {
      variables: {
        id: itemId
      },
      onCompleted: ({content: {[eventityName]: {node: item}}}) => {
        if(item) {
          setFormData(conformResponseToSchema(item, eventity.schema))
        }
      },
    }
  )

  const editMutation = commandMutation(`Update${eventityName}`, ["success"])

  const [editCommand] = useMutation(
    editMutation,
    {
      onCompleted: (data) => {
        refetch()
          .then(() => setEdit(false))

      },
      onError: (e) => {
        console.error(e)
      }
    }
  )

  return (<>
    <ContentHeader
      title={"View Eventity Item"}
      onBack={() => push(`/dashboard/organisation/${organisationId}/workspace/${workspaceId}/workspaceentity/${eventityName}`)}
    />
    <Tabs>
      <TabPane tab={"View Item"} key={"view"}>
        <PanelContainer>

          <Card className={"eventity-item"}>
            {Object.keys(itemNode).length === 0 && <Empty/>}
            {!edit && Object.keys(itemNode).length > 0 &&
              <EventityItem id={itemId} schema={eventity.schema} item={conformResponseToSchema(itemNode, eventity.schema)} type={eventityName}>
                <Button onClick={() => setEdit(true)}>Edit</Button>
              </EventityItem>}
            {edit && Object.keys(itemNode).length > 0 && <GenerateFromJoi
              key={`edit-${eventityName}-${props.itemId}`}
              schema={{...eventity.schema, id: {"@type": "string", required: true, "@meta": {readOnly: true}}}}
              execute={(input) => {
                setFormData(conformResponseToSchema(input, eventity.schema))
                editCommand({variables: {input}})
              }}
              executeName={`Save ${toTitleCase(eventityName)}`}
              data={formData}
              cancel={() => setEdit(false)}
            />}
          </Card>
          <Panels workspaceId={workspaceId} type={"view"} entityName={eventityName}
                  context={{type: eventityName, data: {...conformResponseToSchema(itemNode, eventity.schema), history: itemNode.history}, schema: eventity.schema}}/>
        </PanelContainer>
      </TabPane>
      <TabPane tab={"Item History"} key={"item-stream"}>
        <div>
          <Space>
            <Button onClick={() => void refetch()} loading={loading}>Reload Stream</Button>
          </Space>
        </div>
        <Stream stream={itemNode.history}/>
      </TabPane>
    </Tabs>
  </>)
}
