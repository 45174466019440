import React from "react";

import {Route, RouteComponentProps, Switch} from "react-router";
import ViewEventity from "./Components/ViewEventity";
import {ViewEventityItem} from "./ViewEventityItem";


export const Eventity: React.FC<RouteComponentProps> = ({match}) => {

  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/:eventityName`} component={ViewEventity} />
        <Route path={`${match.url}/:eventityName/:itemId`} component={ViewEventityItem} />
      </Switch>
    </>
  )
}
