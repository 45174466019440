import React, {useEffect} from "react";
import {useReactiveVar} from "@apollo/client";
import {Link} from "react-router-dom";
import {Col, Divider, Layout, notification, Row, Space, Spin} from "antd";
import {OrgList} from "../orgs/OrgList";
import {WorkspaceList} from "../workspace/WorkspaceList";
import {CreateWorkspace} from "../workspace/CreateWorkspace";
import {LogInControls} from "../../components/Auth/LogInControls";
import {UserHeaderMenu} from "../User/UserHeaderMenu";
import {Helmet} from "react-helmet";
import {EVFront, HeaderLogo} from "./style";
import Version from "../../components/Version";
import {AUTH_STATE, authState} from "../../graphql/type-policies/auth";
import {currentWorkspaceId} from "../../graphql/type-policies/workspace";
import AppMenu from "./Components/AppMenu";
import {currentOrgID} from "../../graphql/type-policies/orgs";
import {federationError} from "../../graphql/type-policies/error";

const {Header, Sider, Content} = Layout;

interface MainLayout {}

export const MainLayout: React.FC<MainLayout> = (props) => {
  const organisationId = useReactiveVar(currentOrgID)
  const workspaceId = useReactiveVar(currentWorkspaceId)
  const currentAuthState = useReactiveVar(authState)

  const loggedIn = (): boolean => {
    return currentAuthState === AUTH_STATE.LOGGED_IN
  }

  const fedError = useReactiveVar(federationError)
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    if(fedError) {
      api.error({message: "Federation Error", description: "A remote extension in this workspace failed to federate. Please check the extension source for errors.", duration: 0, placement: "bottomRight", key: "global-warning"})
    } else {
      notification.close("global-warning")
    }
  }, [fedError])

  return (<>
    <Helmet
      defaultTitle={"Eventicle"}
      titleTemplate={"Eventicle - %s"}
    >
      <html lang="en"/>
      <meta charSet="utf-8"/>
    </Helmet>
    {contextHolder}
    <Spin size={"large"} spinning={currentAuthState === AUTH_STATE.WAITING}>
      <EVFront className={"layout main"}>
        <Version/>
        <Header className={"header main"} style={{padding: "0 1rem"}}>
          <Row>
            <Col>
              <Link to={loggedIn() ? "/dashboard" : "/"}>
                <HeaderLogo/>
              </Link>
            </Col>
            <Col flex={"auto"}>
              <Space>
                <OrgList orgId={organisationId}/>
                {organisationId && <WorkspaceList orgId={organisationId} workspaceId={workspaceId}>
                  <Divider/>
                  <CreateWorkspace orgId={organisationId}/>
                </WorkspaceList>}
              </Space>
            </Col>
            <Col xs={4} className={"text-right"}>
              <Space align={"end"} style={{width: "100%", justifyContent: "flex-end"}}>
                <LogInControls loggedIn={loggedIn()}/>
                <UserHeaderMenu/>
              </Space>
            </Col>
          </Row>
        </Header>
        <Layout hasSider={loggedIn()}>
          {loggedIn() && <Sider
            collapsedWidth={0}
            breakpoint={"lg"}
            className={"site-layout-background menu core-menu"}
          >

            <AppMenu workspaceId={workspaceId} organisationId={organisationId} />

          </Sider>}
          <Content style={{padding: '2rem', position: "relative", marginLeft: "200px"}}>
            {props.children}
          </Content>

        </Layout>

      </EVFront>
    </Spin>
  </>);
}
