import {makeVar} from "@apollo/client";

export const LOGGED_IN = "LOGGED_IN"
export const LOGGED_OUT = "LOGGED_OUT"

export enum AUTH_STATE {
  WAITING,
  LOGGED_IN,
  LOGGED_OUT
}

export const loggedIn = makeVar(false)

export const authState = makeVar<AUTH_STATE>(AUTH_STATE.WAITING)

export const logOut = makeVar(false)

export const authFields = {
  isLoggedIn: {
    read() {
      return loggedIn()
    }
  },
  logOutNow: {
    read() {
      return logOut()
    }
  }
}
