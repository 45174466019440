import fetch from 'cross-fetch'
import {ApolloClient, ApolloLink, concat, createHttpLink, InMemoryCache, NormalizedCacheObject} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {typeDefs} from "./type-defs";
import {AuthService} from "../service/auth";
import {getApiUri} from "../service/url";
import {fields} from "./type-policies";
import {currentWorkspaceId} from "./type-policies/workspace";
import {federationError} from "./type-policies/error";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields,
    }
  }
}).restore(typeof window !== "undefined"?(window as any).__APOLLO_STATE__:"{}");

export const authLink = setContext((_: any, {headers, ...context}) => {
  const authorization = AuthService.getAuthToken("Bearer")
  const xEventWorkspace = currentWorkspaceId()
  let newHeaders = {
    ...headers,
  }
  if(xEventWorkspace && xEventWorkspace !== '') {
    newHeaders = {
      ...newHeaders,
      "x-event-workspace": xEventWorkspace,
    }
  }
  return {
    headers: {
      ...newHeaders,
      authorization
    },
    ...context,
  }
})

const httpLink = createHttpLink({
  uri: `${getApiUri()}/graphql`,
  fetch,
})

let client: ApolloClient<NormalizedCacheObject>

export const mergeTypePolicies = () => {

}

export const removeTypePolicies = (types: string[]) => {

}

const federationLink = new ApolloLink((operation, next) => {

  const complete = next(operation)
    .map(response => {
      const context = operation.getContext()
      const {response: {headers}} = context
      if(headers.has("x-failed-federation")) {
        // const federror = JSON.parse(headers.get("x-failed-federation"))
        federationError(true)
      } else {
        federationError(undefined)
      }
      return response
    })

  return complete
})

export {client}

export const initClient = (): void => {
  client = new ApolloClient({
    cache,

    headers: {
      authorization: AuthService.getAuthToken("Bearer"),
    },
    typeDefs,
    ssrForceFetchDelay: 100,
    link: authLink.concat(concat(federationLink, httpLink))
  })
}

export const getClient = () => client
