import React, {useCallback, useEffect, useState} from "react";
import {DEFAULT_LENGTH, formPaginatedResponse, pageSizeOptions} from "../../../../service/pagination";
import {Button, message, Space, Table} from "antd";
import {CreateEventity} from "../../CreateEventity";
import {commandMutation, contentQuery} from "../../../../graphql/queries/generic";
import {useLazyQuery, useMutation} from "@apollo/client";
import {conformResponseToSchema, orderFields} from "../../../../service/joi";
import {ColumnsType} from "antd/es/table";
import {toTitleCase} from "../../../../service/string";
import DeleteEventity from "./Components/DeleteEventity";
import {Link} from "react-router-dom";
import FilterTools from "../../../../components/Common/FilterTools";
import useParamStore from "../../../../hooks/useParamStore";

interface I {
  eventityName: string
  fields: string[]
  schema: any
  eventity: any
  workspaceId: string
  titleField: string
}

const EventityView: React.FC<I> = ({eventityName, fields, schema, eventity, workspaceId, titleField, ...props}) => {
  const {setParam, params: {page, pageSize, hiddenFields = []}} = useParamStore<{page: number, pageSize: number, hiddenFields: string[]}>({page: 1, pageSize: DEFAULT_LENGTH})

  const setPage = (p: number) => setParam("page", p)
  const setHiddenFields = (hf: string[]) => setParam("hiddenFields", hf)
  // const setFilters = (f: string[]) => setParam("filter", f)
  const setPageSize = (ps: number) => setParam("pageSize", ps)

  const [filter, setFilters] = useState<Record<string, any> | undefined>([])
  // const [hiddenFields, setHiddenFields] = useState<string[]>([])

  const [totalCount, setTotalCount] = useState<number>(0)

  const [columns, setColumns] = useState<ColumnsType<any>>([])

  const query = contentQuery(eventityName, ['id', ...fields])

  const [sorts, setSorts] = useState<Array<{ field: string, direction: string }>>([{
    field: titleField,
    direction: "ASC"
  }])

  const toggleSort = useCallback((field: string) => {
    const resort = [...sorts]
    let found = resort.findIndex((s) => s.field === field)
    let s: any[]


    if (found !== -1) {
      const existing = {...resort[found]}
      if (existing.direction === "ASC") {
        existing.direction = "DESC"
        s = [existing]
      } else {
        s = []
      }
    } else {
      s = [{field, direction: "ASC"}]
    }

    setSorts(s)

    listItems({
      variables: {
        page,
        limit: pageSize,
        filter,
        sort: s
      }
    })

  }, [sorts])

  const hasSort = (field: string) => {
    const found = sorts.findIndex((s) => s.field === field)
    return found !== -1
  }

  const getSortDirection = useCallback((field: string) => {
    const found = sorts.find((s) => s.field === field)
    if (found) {
      if (found.direction === "ASC") {
        return "ascend"
      }
      return "descend"
    }
    return undefined
  }, [sorts])

  useEffect(() => {
    updateColumns(schema)
  }, [hiddenFields])

  const updateColumns = (schema) => {

    if (schema) {
      let l1fields = orderFields(schema, fields)
      if (l1fields.length > 0) {
        const tField = l1fields[0]

        const c = [...l1fields
          .filter((f) => !hiddenFields.includes(f))
          .map((field) => {
              let sortOrder;
              if (hasSort(field)) {
                sortOrder = getSortDirection(field)
              }

              const column: any = {
                key: field,
                dataIndex: field,
                title: toTitleCase(field),
                sorter: true,
                sortOrder,
                defaultSortOrder: sortOrder,
                onHeaderCell: (column,) => {
                  return {
                    onClick: () => {
                      toggleSort(field)
                    },
                    style: {cursor: "pointer"}
                  }
                }
              }

              if (hasSort(field)) {
                const sortorder = getSortDirection(field)
                column.sortOrder = sortorder
                column.defaultSortOrder = sortorder
              }

              if (field === titleField) {
                column.render = (text, record, index) => {
                  if (["string", "number"].includes(typeof text)) {
                    return (
                      <Link key={`${field}-${index}`} to={`./${eventityName}/${record.id}`}>{text}</Link>
                    )
                  }
                  return <>[OBJECT]</>
                }
              } else {
                column.render = (text, record, index) => {
                  if (["string", "number"].includes(typeof text)) {
                    return <>{text}</>
                  } else {
                    return <>[OBJECT]</>
                  }
                }
              }
              return column
            }
          ),
          {
            key: "eventity-actions",
            title: "Actions",
            sticky: true,
            render: (_, record) => (
              <Space>
                <DeleteEventity
                  id={record.id}
                  name={record[tField]}
                  onOk={async () => {
                    await deleteItem({variables: {input: {id: record.id}}})
                    if (refetch) {
                      await refetch()
                    }
                  }}
                  loading={deleteLoading}
                />
              </Space>
            )
          }
        ]
        setColumns(c)
      }
    }
  }

  const [listItems, {
    data: {
      content: {[eventityName]: {search: {totalCount: sourceTotalCount, entries}}}
    } = {content: {[eventityName]: {search: formPaginatedResponse<any>({})}}},
    refetch,
    loading
  }] = useLazyQuery<any, { page: number, limit: number, filter?: any, sort?: any[] }>(
    query,
    {
      variables: {
        page,
        limit: pageSize,
        sort: sorts,
      },
      onCompleted({content: {[eventityName]: {search: {totalCount, entries}}}}) {
        updateColumns(schema)
      }
    },
  )

  const mutateDelete = commandMutation(`Delete${eventityName}`, ['success'])

  const [deleteItem, {loading: deleteLoading}] = useMutation(
    mutateDelete,
    {
      onCompleted: () => {
        message.success("Deleted")
      },
      onError: (e) => {
        message.error(e.message)
      }
    }
  )

  useEffect(() => {
    updateColumns(schema)
  }, [sorts])

  useEffect(() => {
    if (fields.length > 0) {
      listItems({
        variables: {
          page,
          limit: pageSize,
          filter,
          sort: sorts
        }
      })
    }
  }, [filter, page, pageSize])

  useEffect(() => {
    if (!loading) {
      setTotalCount(sourceTotalCount)
    }
  }, [loading, sourceTotalCount])

  return (<>
    <div className={"eventity-list"}>
      <FilterTools
        fields={fields}
        titleField={titleField}
        schema={schema || {}}
        onFilter={(filters) => {
          if (page !== 1) {
            setPage(1)
          }
          setFilters(filters)
        }}
        onColumnChange={(fields) => {
          setHiddenFields(fields)
        }}
      />
      <Table
        loading={loading}
        dataSource={conformResponseToSchema(entries ?? [], schema)}
        columns={columns}
        pagination={{
          defaultPageSize: DEFAULT_LENGTH,
          total: totalCount || 0,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions,
          onChange: (p, ps) => {
            setPage(p)
            if (!!ps) {
              setPageSize(ps)
            }
            window.scrollTo(0, 0)
          },
          current: page
        }}
        footer={() => (<>
            <Space direction={"horizontal"}>
              <CreateEventity
                eventityName={eventityName}
                eventity={eventity}
                onComplete={() => {
                  setPage(1)
                  if (refetch) {
                    refetch()
                  }
                }}
              />
              <Button onClick={() => {
                if (refetch) {
                  refetch({
                    page,
                    limit: pageSize,
                    filter,
                    sort: sorts,
                  })
                }
              }} loading={loading}>Reload</Button>
            </Space>
          </>
        )}
      />
    </div>
  </>)
}

export default EventityView
